import Image from 'next/future/image';
import Link from 'next/link';
import { getEdgeUrl } from '@/lib/getEdgeUrl';

const Holidays = () => {
  const desktopLeft = getEdgeUrl(
    'images/homepage/banner/holidays/holidays/desktop-left-v2.png',
  );
  const desktopRight = getEdgeUrl(
    'images/homepage/banner/holidays/holidays/desktop-right-v2.png',
  );
  const mobileLeft = getEdgeUrl(
    'images/homepage/banner/holidays/holidays/mobile-left-v2.png',
  );
  const mobileRight = getEdgeUrl(
    'images/homepage/banner/holidays/holidays/mobile-right-v2.png',
  );

  return (
    <Link href="/collections/hot-deals" prefetch>
      <a className="relative flex h-full w-screen flex-col items-center justify-center overflow-hidden bg-gradient-to-r from-[#EF5863] to-[#C52237] text-white">
        {/* Left Block  */}
        <div className="absolute hidden md:left-[-125px] md:inline-block lg:left-[0px] xl:left-0">
          <div className="h-[280px]">
            <Image
              src={desktopLeft}
              alt="Left Gadgets"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto object-cover object-left"
              quality={100}
              priority
            />
          </div>
        </div>
        {/* Right Block */}
        <div className="absolute hidden md:right-[-125px] md:inline-block lg:right-[0px] xl:right-0">
          <div className="h-[280px]">
            <Image
              src={desktopRight}
              alt="Right Gadgets"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto object-cover object-left"
              quality={100}
              priority
            />
          </div>
        </div>
        {/* Mobile Left */}
        <div className="absolute left-[-30px] xxs:left-0 md:hidden">
          <div className="h-[325px]">
            <Image
              src={mobileLeft}
              alt="Smartphone"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto object-cover object-left"
              priority
            />
          </div>
        </div>
        {/* Mobile Right */}
        <div className="absolute right-[-50px] top-0 xxs:right-0 md:hidden">
          <div className="h-[325px] object-cover object-right-top">
            <Image
              src={mobileRight}
              alt="Smartwatch"
              loading="eager"
              height={800}
              width={800}
              className="h-full w-auto object-cover object-right"
              priority
            />
          </div>
        </div>
        <div className="relative">
          <div className="reebelo-container">
            <div className="flex flex-col items-center justify-center">
              <h1 className="mt-1.5 text-center text-lg font-semibold leading-tight xxs:text-xl xs:text-2xl md:text-3xl xl:text-4xl">
                Happy Holidays!
              </h1>
              <h3 className="mt-2 text-sm sm:text-base">
                Gift More, Spend Less.
              </h3>
              <div className="mt-5 flex h-10 items-center justify-center rounded-full !bg-white px-5 text-xs font-semibold text-[#C52237]">
                Shop Deals
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default Holidays;
